<template>
  <div class="inline-flex gap-2 justify-center items-center">
    <!-- WINS -->
    <div
      v-if="props.wins !== 0"
      v-tooltip.left="{
        value: `Daily Streak`,
        pt: {
          root: ({ context }) => ({
            class: [
              'absolute shadow-md',
              {
                'py-0 px-1':
                  context?.right ||
                  context?.left ||
                  (!context?.right &&
                    !context?.left &&
                    !context?.top &&
                    !context?.bottom),
                'py-1 px-0': context?.top || context?.bottom,
              },
            ],
          }),
          arrow: ({ context }) => ({
            class: [
              'absolute w-0 h-0 border-transparent border-solid',
              {
                '-m-t-1 border-y-[0.25rem] border-r-[0.25rem] border-l-0 border-r-stone-600':
                  context?.right ||
                  (!context?.right &&
                    !context?.left &&
                    !context?.top &&
                    !context?.bottom),
                '-m-t-1 border-y-[0.25rem] border-l-[0.25rem] border-r-0 border-l-stone-600':
                  context?.left,
                '-m-l-1 border-x-[0.25rem] border-t-[0.25rem] border-b-0 border-t-stone-600':
                  context?.top,
                '-m-l-1 border-x-[0.25rem] border-b-[0.25rem] border-t-0 border-b-stone-600':
                  context?.bottom,
              },
            ],
          }),
          text: {
            class:
              'text-sm p-2 bg-stone-600 text-white rounded-md whitespace-pre-line break-words border-2 border-amber-300/60',
          },
        },
      }"
      class="text-slate-950 text-xl w-fit mx-auto">
      <div class="relative mb-2">
        <img src="@/assets/fire-joypixels.gif" class="bg-transparent w-8" />
        <span class="absolute top-1 left-1 right-0 py-1 text-black wins font-semibold text-2xl">
          {{ props.wins }}
        </span>
      </div>
    </div>
    <!-- UPDATES -->
    <div
      @click="visible = true, readUpdates()"
      v-tooltip.right="{
        value: `Updates`,
        pt: {
          root: ({ context }) => ({
            class: [
              'absolute shadow-md',
              {
                'py-0 px-1':
                  context?.right ||
                  context?.left ||
                  (!context?.right &&
                    !context?.left &&
                    !context?.top &&
                    !context?.bottom),
                'py-1 px-0': context?.top || context?.bottom,
              },
            ],
          }),
          arrow: ({ context }) => ({
            class: [
              'absolute w-0 h-0 border-transparent border-solid',
              {
                '-m-t-1 border-y-[0.25rem] border-r-[0.25rem] border-l-0 border-r-stone-600':
                  context?.right ||
                  (!context?.right &&
                    !context?.left &&
                    !context?.top &&
                    !context?.bottom),
                '-m-t-1 border-y-[0.25rem] border-l-[0.25rem] border-r-0 border-l-stone-600':
                  context?.left,
                '-m-l-1 border-x-[0.25rem] border-t-[0.25rem] border-b-0 border-t-stone-600':
                  context?.top,
                '-m-l-1 border-x-[0.25rem] border-b-[0.25rem] border-t-0 border-b-stone-600':
                  context?.bottom,
              },
            ],
          }),
          text: {
            class:
              'text-sm p-2 bg-stone-600 text-white rounded-md whitespace-pre-line break-words border-2 border-amber-300/60',
          },
        },
      }"
      class="relative mx-auto w-fit">
      <div v-if="newUpdate && !checkedUpdates" class="absolute left-7 bottom-7 inline-flex h-2 w-2 rounded-full bg-amber-400 animate__animated animate__infinite animate__pulse"></div>
        <div class="text-white/80 bg-zinc-800 py-1 px-2 rounded border h-max border-stone-200/40 cursor-pointer hover:text-white hover:border-white">
          <span class="pi pi-list"></span>
        </div>
    </div>
    <!-- FAQ -->
    <div
      @click="visibleFAQ = true"
      v-tooltip.right="{
        value: `FAQ`,
        pt: {
          root: ({ context }) => ({
            class: [
              'absolute shadow-md',
              {
                'py-0 px-1':
                  context?.right ||
                  context?.left ||
                  (!context?.right &&
                    !context?.left &&
                    !context?.top &&
                    !context?.bottom),
                'py-1 px-0': context?.top || context?.bottom,
              },
            ],
          }),
          arrow: ({ context }) => ({
            class: [
              'absolute w-0 h-0 border-transparent border-solid',
              {
                '-m-t-1 border-y-[0.25rem] border-r-[0.25rem] border-l-0 border-r-stone-600':
                  context?.right ||
                  (!context?.right &&
                    !context?.left &&
                    !context?.top &&
                    !context?.bottom),
                '-m-t-1 border-y-[0.25rem] border-l-[0.25rem] border-r-0 border-l-stone-600':
                  context?.left,
                '-m-l-1 border-x-[0.25rem] border-t-[0.25rem] border-b-0 border-t-stone-600':
                  context?.top,
                '-m-l-1 border-x-[0.25rem] border-b-[0.25rem] border-t-0 border-b-stone-600':
                  context?.bottom,
              },
            ],
          }),
          text: {
            class:
              'text-sm p-2 bg-stone-600 text-white rounded-md whitespace-pre-line break-words border-2 border-amber-300/60',
          },
        },
      }"
      class="relative mx-auto w-fit">
        <div class="text-white/80 bg-zinc-800 py-1 px-2 rounded border h-max border-stone-200/40 cursor-pointer hover:text-white hover:border-white">
          <span class="pi pi-question-circle"></span>
        </div>
    </div>
  </div>
  <Dialog
    v-model:visible="visible"
    modal
    header="Updates"
    :style="{ width: '50rem' }"
    :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
    :pt="{
      root: ({ state }) => ({
        class: [
          'rounded-lg shadow-lg border border-slate-500/40 max-w-[90%]',
          'max-h-[90%] transform scale-100',
          'm-0 w-[50vw]',
          {
            'transition-none transform-none !w-screen !h-screen !max-h-full !top-0 !left-0':
              state.maximized,
          },
        ],
      }),
      header: {
        class: [
          'flex items-center justify-between shrink-0',
          'bg-zinc-900 text-white/80 border-t-0  rounded-tl-lg rounded-tr-lg p-6',
        ],
      },
      headerTitle: {
        class: 'font-bold text-lg',
      },
      headerIcons: {
        class: 'flex items-center',
      },
      closeButton: {
        class: [
          'flex items-center justify-center overflow-hidden relative',
          'w-8 h-8 text-gray-500 border-0 bg-transparent rounded-full transition duration-200 ease-in-out mr-2 last:mr-0',
          'hover:text-gray-700 hover:border-transparent hover:bg-gray-200',
          'focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)]',
          'dark:hover:text-white/80 dark:hover:border-transparent dark:hover:bg-gray-800/80 dark:focus:shadow-[inset_0_0_0_0.2rem_rgba(147,197,253,0.5)]',
        ],
      },
      closeButtonIcon: {
        class: 'w-4 h-4 inline-block',
      },
      content: ({ state, instance }) => ({
        class: [
          'overflow-y-auto',
          'bg-zinc-900 text-white/80 px-6 pb-8 pt-0',
          {
            grow: state.maximized,
          },
          {
            'rounded-bl-lg rounded-br-lg': !instance.$slots.footer,
          },
        ],
      }),
      footer: {
        class: [
          'flex gap-2 shrink-0 justify-end align-center',
          'border-t-0 bg-white text-gray-700 px-6 pb-6 text-right rounded-b-lg',
          'dark:bg-gray-900  dark:text-white/80',
        ],
      },
      mask: ({ props }) => ({
        class: ['transition duration-200', { 'bg-black/40': props.modal }],
      }),
      transition: ({ props }) => {
        return props.position === 'top'
          ? {
              enterFromClass:
                'opacity-0 scale-75 translate-x-0 -translate-y-full translate-z-0',
              enterActiveClass: 'transition-all duration-200 ease-out',
              leaveActiveClass: 'transition-all duration-200 ease-out',
              leaveToClass:
                'opacity-0 scale-75 translate-x-0 -translate-y-full translate-z-0',
            }
          : props.position === 'bottom'
          ? {
              enterFromClass: 'opacity-0 scale-75 translate-y-full',
              enterActiveClass: 'transition-all duration-200 ease-out',
              leaveActiveClass: 'transition-all duration-200 ease-out',
              leaveToClass:
                'opacity-0 scale-75 translate-x-0 translate-y-full translate-z-0',
            }
          : props.position === 'left' ||
            props.position === 'topleft' ||
            props.position === 'bottomleft'
          ? {
              enterFromClass:
                'opacity-0 scale-75 -translate-x-full translate-y-0 translate-z-0',
              enterActiveClass: 'transition-all duration-200 ease-out',
              leaveActiveClass: 'transition-all duration-200 ease-out',
              leaveToClass:
                'opacity-0 scale-75  -translate-x-full translate-y-0 translate-z-0',
            }
          : props.position === 'right' ||
            props.position === 'topright' ||
            props.position === 'bottomright'
          ? {
              enterFromClass:
                'opacity-0 scale-75 translate-x-full translate-y-0 translate-z-0',
              enterActiveClass: 'transition-all duration-200 ease-out',
              leaveActiveClass: 'transition-all duration-200 ease-out',
              leaveToClass:
                'opacity-0 scale-75 opacity-0 scale-75 translate-x-full translate-y-0 translate-z-0',
            }
          : {
              enterFromClass: 'opacity-0 scale-75',
              enterActiveClass: 'transition-all duration-200 ease-out',
              leaveActiveClass: 'transition-all duration-200 ease-out',
              leaveToClass: 'opacity-0 scale-75',
            };
      },
    }">
    <template #header>
      <div class="border-b w-11/12">
        <span class="font-bold text-2xl white-space-nowrap">UPDATES</span>
      </div>
    </template>
    <div
      class="border rounded bg-zinc-700/80 hover:bg-zinc-700 p-2 my-2"
      v-for="update in updates"
      :key="update.id">
      <h3 class="font-bold text-amber-300/70 uppercase">{{ update.title }}</h3>
      <p>{{ update.description }}</p>
      <p>
        <span class="text-xs italic text-white/60">{{
          convertDate(update.updated)
        }}</span>
        <span class="text-xs font-mono text-white/50">
          - {{ update.build }}
        </span>
      </p>
    </div>
  </Dialog>
  <Dialog
    v-model:visible="visibleFAQ"
    modal
    header="FAQ"
    :style="{ width: '50rem' }"
    :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
    :pt="{
      root: ({ state }) => ({
        class: [
          'rounded-lg shadow-lg border border-slate-500/40 max-w-[90%]',
          'max-h-[90%] transform scale-100',
          'm-0 w-[50vw]',
          {
            'transition-none transform-none !w-screen !h-screen !max-h-full !top-0 !left-0':
              state.maximized,
          },
        ],
      }),
      header: {
        class: [
          'flex items-center justify-between shrink-0',
          'bg-zinc-900 text-white/80 border-t-0  rounded-tl-lg rounded-tr-lg p-6',
        ],
      },
      headerTitle: {
        class: 'font-bold text-lg',
      },
      headerIcons: {
        class: 'flex items-center',
      },
      closeButton: {
        class: [
          'flex items-center justify-center overflow-hidden relative',
          'w-8 h-8 text-gray-500 border-0 bg-transparent rounded-full transition duration-200 ease-in-out mr-2 last:mr-0',
          'hover:text-gray-700 hover:border-transparent hover:bg-gray-200',
          'focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)]',
          'dark:hover:text-white/80 dark:hover:border-transparent dark:hover:bg-gray-800/80 dark:focus:shadow-[inset_0_0_0_0.2rem_rgba(147,197,253,0.5)]',
        ],
      },
      closeButtonIcon: {
        class: 'w-4 h-4 inline-block',
      },
      content: ({ state, instance }) => ({
        class: [
          'overflow-y-auto',
          'bg-zinc-900 text-white/80 px-6 pb-8 pt-0',
          {
            grow: state.maximized,
          },
          {
            'rounded-bl-lg rounded-br-lg': !instance.$slots.footer,
          },
        ],
      }),
      footer: {
        class: [
          'flex gap-2 shrink-0 justify-end align-center',
          'border-t-0 bg-white text-gray-700 px-6 pb-6 text-right rounded-b-lg',
          'dark:bg-gray-900  dark:text-white/80',
        ],
      },
      mask: ({ props }) => ({
        class: ['transition duration-200', { 'bg-black/40': props.modal }],
      }),
      transition: ({ props }) => {
        return props.position === 'top'
          ? {
              enterFromClass:
                'opacity-0 scale-75 translate-x-0 -translate-y-full translate-z-0',
              enterActiveClass: 'transition-all duration-200 ease-out',
              leaveActiveClass: 'transition-all duration-200 ease-out',
              leaveToClass:
                'opacity-0 scale-75 translate-x-0 -translate-y-full translate-z-0',
            }
          : props.position === 'bottom'
          ? {
              enterFromClass: 'opacity-0 scale-75 translate-y-full',
              enterActiveClass: 'transition-all duration-200 ease-out',
              leaveActiveClass: 'transition-all duration-200 ease-out',
              leaveToClass:
                'opacity-0 scale-75 translate-x-0 translate-y-full translate-z-0',
            }
          : props.position === 'left' ||
            props.position === 'topleft' ||
            props.position === 'bottomleft'
          ? {
              enterFromClass:
                'opacity-0 scale-75 -translate-x-full translate-y-0 translate-z-0',
              enterActiveClass: 'transition-all duration-200 ease-out',
              leaveActiveClass: 'transition-all duration-200 ease-out',
              leaveToClass:
                'opacity-0 scale-75  -translate-x-full translate-y-0 translate-z-0',
            }
          : props.position === 'right' ||
            props.position === 'topright' ||
            props.position === 'bottomright'
          ? {
              enterFromClass:
                'opacity-0 scale-75 translate-x-full translate-y-0 translate-z-0',
              enterActiveClass: 'transition-all duration-200 ease-out',
              leaveActiveClass: 'transition-all duration-200 ease-out',
              leaveToClass:
                'opacity-0 scale-75 opacity-0 scale-75 translate-x-full translate-y-0 translate-z-0',
            }
          : {
              enterFromClass: 'opacity-0 scale-75',
              enterActiveClass: 'transition-all duration-200 ease-out',
              leaveActiveClass: 'transition-all duration-200 ease-out',
              leaveToClass: 'opacity-0 scale-75',
            };
      },
    }">
    <template #header>
      <div class="border-b w-11/12">
        <span class="font-bold text-2xl white-space-nowrap">FAQ</span>
      </div>
    </template>
    <div class="bg-zinc-700/80 border rounded p-2 mb-3">
      <div class="text-xl text-amber-300/70 font-semibold">HOW TO PLAY</div>
      <div class="mt-2">
        <p>Daily character guessing game!</p>
        <p>The game resets every 24h, at <span class="font-mono">12AM(00:00) UTC 0</span>.</p>
        <br>
        <p>Each reset you have to guess new random character from playable characters in <span class="font-semibold text-amber-300/70">Honkai: Star Rail</span>.<br/><span class="text-sm italic">NOTE: All Trailblazer forms are treated as seperate characters.</span></p>
        <br>
        <p class="text-lg font-semibold">Color Indicators:</p>
        <ul>
          <li><span class="text-green-600 font-semibold">GREEN</span> - Exact feature match</li>
          <li><span class="text-amber-600 font-semibold">ORANGE</span> - Partial feature match</li>
          <li><span class="text-red-600 font-semibold">RED</span> - No feature match</li>
        </ul>
        <br>
        <p class="text-lg font-semibold">Example:</p>
        <p>If today's random character is <span class="text-amber-300/70">{{ characterRandomExample.name }}</span>
        and you enter <span class="text-amber-300/70">{{ characterGuessExample.name }}</span></p>
        <p class="mt-2">Here is what you would get:</p>
        <!-- GUESS CONTAINER -->
        <div
          class="overflow-x-auto text-xs w-full font-semibold sm:text-sm md:text-base lg:text-lg xl:text-sm flex flex-col mt-3 text-center md:font-semibold cursor-default"
          >
          <div
            class="flex flex-row md:gap-0.5 text-center mx-auto leading-4 text-white tracking-tighter sm:tracking-normal gap-0"
            >
            <div
              class="w-14 h-14 md:w-20 md:h-20 lg:w-20 lg:h-20 xl:w-24 xl:h-24 rounded image-container test-class"
              >
              <img
                :alt="characterGuessExample.name"
                :src="characterGuessExample.image"
                style="width: 100%; height: auto"
                class="border border-slate-400 mx-auto rounded image z-40" />
              <div class="middle">
                <div class="text rounded-md py-0.5 px-2 z-50">{{ characterGuessExample.name }}</div>
              </div>
            </div>
            <div
              class="w-14 h-14 md:w-20 md:h-20 lg:w-20 lg:h-20 xl:w-24 xl:h-24 rounded p-0.5 line border border-slate-400 test-class incorrect"
              >
              {{ characterGuessExample.rarity }}
            </div>
            <div
              class="w-14 h-14 md:w-20 md:h-20 lg:w-20 lg:h-20 xl:w-24 xl:h-24 rounded p-0.5 line border border-slate-400 test-class correct"
              :class="
                {
                  'font-clamp-tighter': (characterGuessExample.path === 'Preservation') || (characterGuessExample.path === 'Destruction')
                }
              "
              >
              {{ characterGuessExample.path }}
            </div>
            <div
              class="w-14 h-14 md:w-20 md:h-20 lg:w-20 lg:h-20 xl:w-24 xl:h-24 rounded p-0.5 line border border-slate-400 test-class incorrect"
              >
              {{ characterGuessExample.type }}
            </div>
            <div
              class="w-14 h-14 md:w-20 md:h-20 lg:w-20 lg:h-20 xl:w-24 xl:h-24 rounded p-0.5 line border border-slate-400 test-class incorrect"
              >
              {{ characterGuessExample.species }}
            </div>
            <div
              class="w-14 h-14 md:w-20 md:h-20 lg:w-20 lg:h-20 xl:w-24 xl:h-24 rounded p-0.5 line border border-slate-400 test-class correct"
              >
              {{ characterGuessExample.gender }}
            </div>
            <div
              class="w-14 h-14 md:w-20 md:h-20 lg:w-20 lg:h-20 xl:w-24 xl:h-24 rounded p-0.5 line border border-slate-400 test-class md:font-clamp bg-gradient-to-r from-amber-600 via-amber-700 to-amber-600 hover:from-amber-500 hover:to-amber-500"
              >
              <span class="inline">
                <span v-for="(world, index) in characterGuessExample.world" :key="index">
                  {{ world
                  }}<span v-if="index < characterGuessExample.world.length - 1">,<br /></span>
                </span>
              </span>
            </div>
          </div>
        </div>
        <!-- GUESS CONTAINER END -->
        <ul class="mt-2">
          <li>- Rarity is <span class="text-red-600">red</span> because {{ characterRandomExample.name }} is a {{ characterRandomExample.rarity }} star character</li>
          <li>- Path is <span class="text-green-600">green</span> because {{ characterRandomExample.path }} is same path as for {{ characterRandomExample.name }}</li>
          <li>- Type is <span class="text-red-600">red</span> because {{ characterRandomExample.name }} is {{ characterRandomExample.type }} character</li>
          <li>- Species is <span class="text-red-600">red</span> because {{ characterRandomExample.name }} is {{ characterRandomExample.species }}</li>
          <li>- Presumed gender is <span class="text-green-600">green</span> because {{ characterRandomExample.name }}'s presumed gender is {{ characterRandomExample.gender }}</li>
          <li>- World(s) is <span class="text-amber-600">orange</span> because {{ characterRandomExample.name }} is both from {{ characterRandomExample.world[0] }} and from {{ characterRandomExample.world[1] }}</li>
        </ul>
        <!-- EXPLANATION END -->

        <!-- CORRECT GUESS START -->
        <p class="mt-2">And finally if you enter <span class="text-amber-300/70">{{ characterRandomExample.name }}</span> this will be the result:</p>
        <div
          class="overflow-x-auto text-xs w-full font-semibold sm:text-sm md:text-base lg:text-lg xl:text-sm flex flex-col mt-3 text-center md:font-semibold cursor-default"
          >
          <div
            class="flex flex-row md:gap-0.5 text-center mx-auto leading-4 text-white tracking-tighter sm:tracking-normal gap-0"
            >
            <div
              class="w-14 h-14 md:w-20 md:h-20 lg:w-20 lg:h-20 xl:w-24 xl:h-24 rounded image-container test-class"
              >
              <img
                :alt="characterRandomExample.name"
                :src="characterRandomExample.image"
                style="width: 100%; height: auto"
                class="border border-slate-400 mx-auto rounded image z-40" />
              <div class="middle">
                <div class="text rounded-md py-0.5 px-2 z-50">{{ characterRandomExample.name }}</div>
              </div>
            </div>
            <div
              class="w-14 h-14 md:w-20 md:h-20 lg:w-20 lg:h-20 xl:w-24 xl:h-24 rounded p-0.5 line border border-slate-400 test-class correct"
              >
              {{ characterRandomExample.rarity }}
            </div>
            <div
              class="w-14 h-14 md:w-20 md:h-20 lg:w-20 lg:h-20 xl:w-24 xl:h-24 rounded p-0.5 line border border-slate-400 test-class correct"
              :class="
                {
                  'font-clamp-tighter': (characterRandomExample.path === 'Preservation') || (characterRandomExample.path === 'Destruction')
                }
              "
              >
              {{ characterRandomExample.path }}
            </div>
            <div
              class="w-14 h-14 md:w-20 md:h-20 lg:w-20 lg:h-20 xl:w-24 xl:h-24 rounded p-0.5 line border border-slate-400 test-class correct"
              >
              {{ characterRandomExample.type }}
            </div>
            <div
              class="w-14 h-14 md:w-20 md:h-20 lg:w-20 lg:h-20 xl:w-24 xl:h-24 rounded p-0.5 line border border-slate-400 test-class correct"
              >
              {{ characterRandomExample.species }}
            </div>
            <div
              class="w-14 h-14 md:w-20 md:h-20 lg:w-20 lg:h-20 xl:w-24 xl:h-24 rounded p-0.5 line border border-slate-400 test-class correct"
              >
              {{ characterRandomExample.gender }}
            </div>
            <div
              class="w-14 h-14 md:w-20 md:h-20 lg:w-20 lg:h-20 xl:w-24 xl:h-24 rounded p-0.5 line border border-slate-400 test-class md:font-clamp correct leading-none md:leading-4 tighter-text"
              >
              <span class="inline">
                <span v-for="(world, index) in characterRandomExample.world" :key="index">
                  {{ world
                  }}<span v-if="index < characterRandomExample.world.length - 1">,<br /></span>
                </span>
              </span>
            </div>
          </div>
        </div>
        <!-- CORRECT GUESS END -->

        <!-- POSSIBLE VALUES START -->
        <div class="mt-6">
          <p class="text-lg font-semibold">Possible Values:</p>
          <ul>
            <li><span class="text-amber-300/80">Rarity</span>: 4 and 5</li>
            <li class="mt-1" v-for="(value, key, index) in uniques" :key="index">
              <span class="capitalize text-amber-300/80">{{ key }}</span>: 
              <span v-for="(v, i) in value" :key="i"> {{ v }}<span v-if="i < value.length - 2">, </span><span v-else-if="i < value.length - 1"> and </span></span>
            </li>
          </ul>
        </div>
        <!-- POSSIBLE VALUES END -->

        <!-- FOOTER -->
        <div class="mt-10 border-t">
          <p>If you have any questions or suggestions please feel free to contact me in <span class="font-semibold">English</span> at <span class="text-stone-400">starraidle@gmail.com</span></p>
          <p class="mt-6">Good luck & Have fun!	&#x1F3C6;</p>
        </div>
        <!-- FOOTER END -->

      </div>
    </div>
  </Dialog>
</template>

<script setup>
import Dialog from "primevue/dialog";
import { ref, onMounted } from "vue";
import getUpdates from "@/api/getUpdates";
import getCharacterByName from "@/api/getCharacterByName";
import getUnique from "@/api/getUniques";

const visible = ref(false);
const visibleFAQ = ref(false);
const updates = ref([]);
const lastEdited = ref(0);
const newUpdate = ref(false);
const checkedUpdates = ref(false);
const characterRandomExample = ref({});
const characterGuessExample = ref({});
const uniques = ref({});
// eslint-disable-next-line no-undef
const props = defineProps({
  wins: Number,
});

onMounted(async () => {
  const fetchedUpdates = await getUpdates();
  updates.value = fetchedUpdates.updates.reverse();
  lastEdited.value = fetchedUpdates.info.edited;
  const currentTimestamp = Math.floor(Date.now() / 1000);
  const difference = currentTimestamp - lastEdited.value;
  newUpdate.value = difference < 24 * 60 * 60 * 1000;
  const ifReadUpdates = localStorage.getItem("checkedUpdates");
  ifReadUpdates === "true" ? checkedUpdates.value = true : '';

  // EXAMPLE
  const fetchedExampleCharacter = await getCharacterByName("Jingliu"); //Jingliu
  characterRandomExample.value = fetchedExampleCharacter;
  const fetchedGuessCharacter = await getCharacterByName("Xueyi"); // Xueyi
  characterGuessExample.value = fetchedGuessCharacter;

  // UNIQUES
  const fetchedUniques = await getUnique();
  uniques.value = fetchedUniques;
});

const readUpdates = () => {
  localStorage.setItem("checkedUpdates", 'true');
  checkedUpdates.value = localStorage.getItem("checkedUpdates");
}

const convertDate = (timestamp) => {
  const date = new Date(timestamp * 1000);
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  };
  const userLocale = navigator.language || navigator.userLanguage;
  const formattedDate = date.toLocaleString(userLocale, options);
  return formattedDate;
};
</script>

<style scoped>
.wins {
  /* -webkit-text-stroke: 0.10px rgb(251, 191, 36); */
  -webkit-text-stroke: 0.1px #fbbf24;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
}

.line {
  display: flex;
  justify-content: center;
  align-items: center;
}

.font-clamp {
  font-size: clamp(11px, 1.5vw, 15px) !important;
}

.font-clamp-tighter {
  font-size: clamp(10px, 1.5vw, 15px) !important;
}

.tighter-text {
  @media only screen and (max-width: 600px) {
    font-size: clamp(9px, 1.5vw, 15px) !important;
  }
}

.image-container {
  position: relative;
}

.image {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.image-container:hover .image {
  opacity: 0.3;
}

.image-container:hover .middle {
  opacity: 1;
}

.text {
  background-color: #343a38;
  color: white;
  border: 1px solid white;
  border-radius: 2px;
  font-size: 13px;
}

.test-class {
  box-shadow: 2px 4px 2px rgba(0, 0, 0, 0.8);
}
</style>