import axios from 'axios';

const getRandomCharacter = async () => {
    // Local
    // let apiR = 'http://192.168.0.32:3000/api/character';
    // Deploy test
    // let apiR = 'https://starraidle-api.onrender.com/api/character';
    
    // DO Test
    let apiR = 'https://starraidle-backend.com/api/character';

    const character = await axios.get(apiR);
    // console.log(character.data.name);
    return character.data;
}

export default getRandomCharacter;