import axios from 'axios';

// Local
// const api = 'http://192.168.0.32:3000/api/unique';

// Deploy test
// const api = 'https://starraidle-api.onrender.com/api/unique';

// DO Test
const api = 'https://starraidle-backend.com/api/unique';

const getUnique = async () => {
    const unique = await axios.get(api);
    try {
        return unique.data;
    } catch (error) {
        console.error(error);
    }
}

export default getUnique;