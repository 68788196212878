import axios from 'axios';

const getCharacterById = async (id) => {
    // Local
    // let apiById = 'http://192.168.0.32:3000/api/character/'

    // Deploy test
    // let apiById = 'https://starraidle-api.onrender.com/api/character/';

    // DO Test
    let apiById = 'https://starraidle-backend.com/api/character/';

    const character = await axios.get(apiById + id);
    // console.log(character.data.name);
    return character.data.characterById;
}

export default getCharacterById;