import axios from 'axios';

const getCharacterByName = async (name) => {
    // Local
    // let apiByName = 'http://192.168.0.32:3000/api/character/name/';

    // Deploy test
    // let apiById = 'https://starraidle-api.onrender.com/api/character/';

    // DO Test
    let apiByName = 'https://starraidle-backend.com/api/character/name/';
    const character = await axios.get(apiByName + name);
    // console.log(character.data.name);
    return character.data.characterByName;
}

export default getCharacterByName;